@font-face {
  font-family: 'SourceHanSansSC';
  src: url('./assets/fonts/SourceHanSansSC-Normal-Mini.ttf');
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  overflow: hidden;
  background-color: rgb(245, 245, 245);
}
body {
  font-family: 'SourceHanSansSC', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
